<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmGpathRoadmapFormData && Object.keys(vmGpathRoadmapFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationname">
                    {{cvNameLabel}}</label
                  >
                  <input
                    v-model="vmGpathRoadmapFormData.name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationheader_description">
                    {{cvHeaderDescriptionLabel}}</label>
                  <textarea
                    v-model="vmGpathRoadmapFormData.header_description"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationfooter_description">
                    {{cvFooterDescriptionLabel}}</label>
                  <textarea
                    v-model="vmGpathRoadmapFormData.footer_description"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationhighlightdata">
                    {{cvHighlightdataLabel}}</label>
                  <textarea
                    v-model="vmGpathRoadmapFormData.highlightdata"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationhighlightTitle">
                    {{cvHighlightTitleLabel}}</label>
                  <input
                    v-model="vmGpathRoadmapFormData.highlightTitle"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmark">
                    {{cvMarkLabel}}</label
                  >
                  <textarea
                    v-model="vmGpathRoadmapFormData.mark"
                    type="text"
                    class="form-control textarea"
                    required
                  ></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcourses_css">
                    {{cvCoursesCssLabel}}</label
                  >
                  <textarea
                    v-model="vmGpathRoadmapFormData.courses_css"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationdetail">
                    {{cvDetailLabel}}</label
                  >
                  <textarea
                    v-model="vmGpathRoadmapFormData.detail"
                    type="text"
                    class="form-control textarea"
                    required></textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsubHeading">
                    {{cvSubHeadingLabel}}</label
                  >
                  <textarea
                    v-model="vmGpathRoadmapFormData.subHeading"
                    type="text"
                    class="form-control textarea"
                    required
                  ></textarea>
                </div>
                </div>
                <div class="form-group">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning"></b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary" @click="gpathRoadmapEdit()">{{cvSubmitBtn}}</button>
                </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GpathRoadmaps } from "../../../FackApi/api/GpathRoadmap"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
export default {
  name: "GpathRoadmapEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGpathRoadmapObj: {
      type: Object,
      default: function () {
        return {
          "name": "",
          "header_description": "",
          "footer_description": "",
          "highlightdata": "",
          "highlightTitle": "",
          "mark": "",
          "courses_css": "",
          "detail": "",
          "subHeading": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "gpath_roadmap_edit",
      cvCardTitle: "Edit GPaths Roadmap",
      cvCardSubHeader: "Edit GPaths Roadmap",
      cvSubmitBtn: "Edit",
      cvNameLabel: "name",
      cvHeaderDescriptionLabel: "header description",
      cvFooterDescriptionLabel: "footer description",
      cvHighlightdataLabel: "highlightdata",
      cvHighlightTitleLabel: "highlightTitle",
      cvMarkLabel: "mark",
      cvCoursesCssLabel: "courses css",
      cvDetailLabel: "detail",
      cvSubHeadingLabel: "subHeading",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GpathRoadmap Edit Response",
      vmGpathRoadmapFormData: {}
    }
  },
  mounted () {
    socialvue.index()
    this.gpathRoadmapView()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGpathRoadmapFormData) {
          if (!this.vmGpathRoadmapFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * gpathRoadmapView
     */
    async gpathRoadmapView () {
      try {
        if (this.propOpenedInModal) {
          this.vmGpathRoadmapFormData = this.propGpathRoadmapObj
        }
        else {
          let gpathRoadmapId = this.$route.params.grmap_id
          let gpathRoadmapViewResp = await GpathRoadmaps.gpathRoadmapView(this, gpathRoadmapId)

          if (gpathRoadmapViewResp && gpathRoadmapViewResp.resp_status) {
            this.vmGpathRoadmapFormData = gpathRoadmapViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapView() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapEdit
     */
    async gpathRoadmapEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gpathRoadmapAddResp = await GpathRoadmaps.gpathRoadmapEdit(this, this.vmGpathRoadmapFormData)
        await ApiResponse.responseMessageDisplay(this, gpathRoadmapAddResp)

        if (gpathRoadmapAddResp && !gpathRoadmapAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGpathRoadmapEditModal", this.vmGpathRoadmapFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
