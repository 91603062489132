/*eslint-disable */
// import GpathRoadmapModel from '../../Model/GpathRoadmap'
import request from '../../Utils/curl'

let GpathRoadmaps = {
  /**
   * gpathRoadmapList
   */
  async gpathRoadmapList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_roadmap_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpathRoadmapList() and Exception:',err.message)
    }
  },

  /**
   * gpathRoadmapView
   */
  async gpathRoadmapView (context, gpathRoadmapId) {
    try {      
      let post_data = new FormData();
      post_data.append('grmap_id', gpathRoadmapId);
      return await request.curl(context, "gpath_roadmap_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpathRoadmapView() and Exception:",err.message)
    }    
  },

  /**
   * gpathRoadmapAdd
   */
  async gpathRoadmapAdd (context, gpathRoadmapObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpathRoadmapObj) {
        post_data.append(key, gpathRoadmapObj[key]);
      }

      return await request.curl(context, "gpath_roadmap_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpathRoadmapAdd() and Exception:',err.message)
    }
  },

  /**
   * gpathRoadmapEdit
   */
  async gpathRoadmapEdit (context, gpathRoadmapObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpathRoadmapObj) {
        post_data.append(key, gpathRoadmapObj[key]);
      }

      return await request.curl(context, "gpath_roadmap_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpathRoadmapEdit() and Exception:',err.message)
    }
  },

  /**
   * gpathRoadmapDelete
   */
  async gpathRoadmapDelete (context, gpathRoadmapId) {
    try{
      let post_data = new FormData();
      
      post_data.append('grmap_id', gpathRoadmapId);

      return await request.curl(context, "gpath_roadmap_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpathRoadmapDelete() and Exception:',err.message)
    }
  }
}

export {
  GpathRoadmaps
}
